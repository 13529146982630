"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var config = {
    api: {
        siteStaffApiBaseUrl: 'https://3dqjptzz70.execute-api.us-east-1.amazonaws.com/stage/site-staff/graphql',
        commonApi: 'https://3dqjptzz70.execute-api.us-east-1.amazonaws.com/stage/common',
        apiKey: 'xablauapikeyxablau123-stage',
        webSocketBaseUrl: 'wss://a4w00da8c0.execute-api.us-east-1.amazonaws.com/stage'
    },
    cloudfront: 'd17hqorr29jksg.cloudfront.net',
    cognito: {
        siteStaffUserPoolId: 'us-east-1_lUA5M6ahH',
        siteStaffUserPoolClientId: '6jk56lluotfd73hsefko4c30vl'
    },
    pinpoint: 'dab104cf8b74420787417f6e71653be7',
    region: 'us-east-1',
    secretsManager: {
        mapsApiKey: 'AIzaSyDLP8REBjQ8Goq1vIyX522we4aMnByRU7k'
    }
};
exports.default = config;
